<template>
    <div>
        <!-- <b-row>
            <b-col md="3" lg="3" cols="12">
                <wallet-card-view @showModal="showModal" @click="activeWalletType = 1" title="Mining Pool" :type="1" :wallet="pool" :isActive="activeWalletType === 1" />
                <wallet-card-view @showModal="showModal" @click="activeWalletType = 2" title="Payments" :type="2" :wallet="payments" :isActive="activeWalletType === 2" />
            </b-col>
        </b-row> -->

          <div class="d-flex">
            <div  style="width: 272px;">
                <div style="position: sticky; top: 16px;">
                    <wallet-card-view @showModal="showModal" @click="updateWalletType(1)" title="Mining Pool" :type="1" :wallet="pool" :isActive="activeWalletType === 1" />
                    <wallet-card-view @showModal="showModal" @click="updateWalletType(2)" title="Payments" :type="2" :wallet="payments" :isActive="activeWalletType === 2" />
                    <wallet-card-view @showModal="showModal" @click="updateWalletType(3)" title="Клиентский баланс" :type="3" :wallet="clientsPool" :isActive="activeWalletType === 3" />
                </div>
            </div>
            <div v-if="this.activeWalletType === 1 || this.activeWalletType === 2" style="width: calc(100% - 272px); padding-left: 32px;">
                <transactions-desktop-view ref="transactions" :pool_tx_count="pool.tx_count" :payments_tx_count="payments.tx_count" />
            </div>
            <div v-if="this.activeWalletType === 3" style="width: calc(100% - 272px); padding-left: 32px;">
                <clients-desktop-view ref="clients" />
            </div>
        </div>

          
        <b-modal id="input-modal" v-model="modalsVisibility.input" title="Ввод средств" centered hide-footer>
            <input-modal-content :wallet="pool"/>
        </b-modal>
        
        <b-modal id="output-modal" v-model="modalsVisibility.output" title="Вывод средств" centered hide-footer >
            <output-modal-content @afterTransactionSend="afterTransactionSend" :wallet="payments" @close="closeModal" />
        </b-modal>
     
    </div>
</template>

<script>

    import WalletCardView from "./widgets/WalletCardView"

    import InputModalContent from "./modals/input"
    import OutputModalContent from "./modals/output"

    import TransactionsDesktopView from "./transactions/transactions.desktop"
    import ClientsDesktopView from "./clients/clients.desktop"

    export default {

        data() {
            return {
                pool: {
                    tx_count: 0,
                    balance: 0
                },
                payments: {
                    tx_count: 0,
                    balance: 0
                },
                clientsPool: {
                    tx_count: 0,
                    balance: 0
                },
                activeWalletType: 1,

                modalsVisibility: {
                    input: false,
                    output: false,
                }
            }
        },
        methods: {
            closeModal( action ) {
                    
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                    
            },
                
            showModal( action ) {
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },

            afterTransactionSend( args ) {
                this.update();
            },

            update() {
                this.$request.get("finance/wallets").then( rsp => {
                this.pool = rsp.pool;
                this.payments = rsp.payments;
                this.clientsPool.balance = rsp.clients_pool.balance
            });
                    
            },

            updateWalletType(value) {
                this.activeWalletType = value
                if (value !== 3) {
                    this.$refs.transactions.changeWallet( value );
                }
            }
        },
        components: {
            WalletCardView,
            InputModalContent,
            OutputModalContent,

            TransactionsDesktopView,
            ClientsDesktopView
        },
        watch: {
        },
        mounted() {
            this.$request.get("finance/wallets").then( rsp => {
                this.pool = rsp.pool;
                this.payments = rsp.payments;
                this.clientsPool.balance = rsp.clients_pool.balance
            });
        }
    }

</script>