<template>
    <b-card @click="$emit('click')" no-body :bg-variant="isActive ? '' : 'transparent' " :class=" isActive ? 'is-picker' : 'is-picker with-border' " >
        
        <b-card-header>
            <b-card-title class="pb-0">
                {{ title }}
            </b-card-title>
        </b-card-header>
        
        <b-card-body v-if="!wallet">
            <b-overlay show spinner-variant="primary" variant='transparent' style="min-height: 125px;"></b-overlay>
        </b-card-body>
        <b-card-body v-else style="padding: 24px; padding-top: 0;">
            <p style="margin-bottom: 6px; font-size: 28px; font-weight: 600; line-height: 32px;">{{ wallet.balance.toFixed(7) }} ₿</p>
            <span class="text-muted">≈ {{ currency_balance }} {{ symbol }}</span>
            <template v-if="isActive">
                <b-row v-if="type === 1"  style="margin-top: 20px;">
                    <b-col cols="12" style="padding-left: 5px; padding-right: 5px;">
                        <b-button @click="showModal('input')" variant="primary" block style="font-weight: 400; font-size: 14px; border-radius: 6px;">Ввод</b-button>
                    </b-col>
                </b-row>
                <b-row v-else-if="type === 2" style="margin-top: 20px;">
                    <b-col cols="12" style="padding-left: 5px; padding-right: 5px;">
                        <b-button @click="showModal('output')" variant="gray" block style="font-weight: 400; font-size: 14px; border-radius: 6px;">Вывод</b-button>
                    </b-col>
                </b-row>
            </template>
        </b-card-body>
        
    </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"
    
    export default {

        data() {
            return {}
        },
        props: {
            title: {
                type: String,
                default: ""
            },
            wallet: {
                type: Object
            },
            isActive: {
                type: Boolean,
                default: false
            },
            type: {
                type: Number,
                default: false
            }
        },
        methods: {
            showModal( action ) {
                this.$emit("showModal", action );
            }
        },
        components: {

        },
        computed: {
            symbol() {
                return User.getSymbol();
            },
            currency_balance() {
                return (Blockchain.tickers.values[User.currency].last * this.wallet.balance).formatMoney(2," "," ");
            }
        },
        watch: {

        },
        mounted() {

        }

    }

</script>