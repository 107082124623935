<template>
    <div>
        
        <div >
            <p class="text-muted" style="font-size: 13px; line-height: 20px;">Переведите биткоин (BTC) любым удобным для Вас способом на указаный адрес в течение ближайших 15 минут.</p>
            <p class="text-muted" style="font-size: 13px; line-height: 20px;">Оплата будет засчитана после подтверждения всех транзакций.</p>
            <p class="text-muted" style="font-size: 13px; line-height: 20px;">Отправка монеты или токена, кроме BTC по этому адресу может привести к потере средств.</p>
        </div>
        
        <div class="data-form">
            <b-form-group>
                <span class="text-muted" style="font-size: 12px;">Куда</span>
                <p class="m-0">Расчетный кошелек</p>
            </b-form-group>
            <b-form-group>
                <div class="d-flex justify-content-between">
                    <div style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">Сумма к пополнению</span>
                        <b-form-input v-model="amount" placeholder="0,00" type="number" />
                    </div>
                    <div class="mt-auto mb-auto">
                        <b-dropdown
                            :text="ticker"
                            variant="muted"
                            size="sm"
                            right
                            no-caret
                          >
                            
                            <template #button-content>
                                <div class="d-flex">
                                    <span>{{ ticker }}</span>
                                    <feather-icon icon="ChevronDownIcon" style="margin: auto 2px;" />
                                </div>
                            </template>

                            <!-- <b-dropdown-item @click="changeCurrencyType(User.currency)">{{ User.currency }}</b-dropdown-item> -->
                            <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </b-form-group>
        </div>
        
        <div class="d-flex">
            
            <div style="margin-right: 26px;">
                <b-form-group class="form-group-with-label">
                    <div class="d-flex justify-content-between"> 
                        <div ref="container" class="wordwrap">
                            <span class="text-muted" style="font-size: 12px;">Адрес BTC кошелька</span>
                            <p style="font-size: 16px; font-weight: 500; margin: 0; word-break: break-all;">{{ wallet.address }}</p>
                        </div>
                        <div v-ripple @click="copyBitcoinAddress" style="margin: auto 12px; margin-right: 0;">
                            <feather-icon icon="CopyIcon" size="20" class="text-muted cursor-pointer" />
                        </div>
                    </div>
                </b-form-group>
            </div>
            
            <div>
                <qrcode :value="qr_code_value" :options="{ width: 80, margin: 0 }"></qrcode>
            </div>
            
        </div>
        
    </div>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {

        props: {
            wallet: {
                type: Object,
                default: {
                    address: null
                }
            }
        },
        data() {
            return {
                User,
                amount: null,
                ticker: 'BTC'
            }
        },
        methods: {
            
            copyBitcoinAddress( args ) {
                
                let container = this.$refs.container;
                this.$copyText( this.wallet.address, container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Скопировано.',
                      text: 'Bitcoin адрес успешно скопирован в буфер обмена.',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },
            
            changeCurrencyType( ticker ) {
                
                if( ticker === this.ticker ) return;
                
                if( this.amount ) {
                    switch (ticker) {
                        case "BTC" :  this.amount =  this.amount / Blockchain.tickers.values[User.currency].last; break;
                        default:   this.amount = this.amount * Blockchain.tickers.values[User.currency].last; break;
                    }
                }
                
                this.ticker = ticker;
            }
        },
        computed: {
            
            amount_for_send() {
                
               if( this.amount ) {
                   
                   if( this.ticker === 'BTC' ) {
                       return this.amount;
                   }
                   
                   return this.amount / Blockchain.tickers.values[User.currency].last;
                   
               } 
               
               return null;
            },
            
            qr_code_value() {
                
                let result = `bitcoin:${User.self.wallet.address}`;
                
                if( this.amount_for_send ) {
                    result = result + `?amount=${this.amount_for_send}`;
                }
                
                return result;
                
            }
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>