<template>

    <b-overlay
        :show="is_loading"
        blur="50"
        variant='white'
        spinner-variant="primary"
        spinner-lg
        rounded="lg"
    > 
        <transition name="zoom-fade" mode="out-in">
            <div v-if="tx">
                <div class="d-flex">
                    <Lottie 
                        :options="animationData" 
                        :height="100" 
                        :width="200" 
                    />
                </div>

                <div class="d-flex justify-content-around">
                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">Откуда</span>
                        <p class="m-0">{{ select.label }}</p>
                    </b-form-group>

                    <div class="icon-gray-bg mt-auto mb-auto d-flex" style="width: 18px; height: 18px; border-radius: 18px; margin-left: 12px; margin-right: 12px;">
                        <feather-icon icon="ChevronRightIcon" class="text-muted m-auto" />
                    </div>

                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">Куда</span>
                        <p class="m-0"><b-link class="text-black">{{ start_and_end(tx.address) }}</b-link></p>
                    </b-form-group>
                </div>

                <b-list-group flush class="no-hover" style="margin-top: 16px;">
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            Номер транзакции
                        </span>
                        <span>
                            <b-link class="text-black">{{ start_and_end( tx.txid ) }}</b-link>
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            Сумма перевода
                        </span>
                        <span class="text-black ">
                            {{ tx.amount }} BTC
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            Размер комиссии
                        </span>
                        <span class="text-black ">
                            {{ Math.abs(tx.fee) }} BTC
                        </span>
                    </b-list-group-item>

                </b-list-group>
                
                <b-row>
                    
                    <b-col>
                        <b-button @click="modalClose" variant="gray" block size="lg" style="border-radius: 16px; margin-top: 22px;">
                            Посмотреть историю
                        </b-button>
                    </b-col>
                </b-row>

            </div>

            <div v-else class="data-form">
                <b-form-group>
                    <div class="d-flex justify-content-between">
                        <div style="flex-grow: 1">
                            <span class="text-muted" style="font-size: 12px;">Откуда</span>
                            <v-select
                              id="output-select"
                              :options="wallets"
                              v-model="select"
                              label="label"
                              :clearable="false"
                              :filterable="false"
                            />     
                        </div>
                        <feather-icon @click="focusDropdown" icon="ChevronDownIcon" class="text-muted cursor-pointer" size="18" style="margin: auto 2px;" />
                    </div>            
                </b-form-group>
                <b-form-group>
                    <span class="text-muted" style="font-size: 12px;">Адрес получателя BTC</span>
                    <b-form-input v-model="address" />
                </b-form-group>
                <b-form-group>
                    <div class="d-flex justify-content-between">
                        <div style="flex-grow: 1;">
                            <span class="text-muted" style="font-size: 12px;">Сумма вывода</span>
                            <b-form-input v-model="amount" placeholder="0,00" type="number" />
                        </div>
                        <div class="mt-auto mb-auto">
                            <b-dropdown
                                :text="ticker"
                                variant="muted"
                                size="sm"
                                right
                                no-caret
                              >

                                <template #button-content>
                                    <div class="d-flex">
                                        <span class="text-black mb-auto mb-auto">{{ ticker }}</span>
                                        <feather-icon icon="ChevronDownIcon" style="margin: auto 2px;" />
                                    </div>
                                </template>

                                <!-- <b-dropdown-item @click="changeCurrencyType(currency)">{{ currency }}</b-dropdown-item> -->
                                <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </b-form-group>

                <div class="d-flex justify-content-between" style="font-size: 12px;">
                    <span class="text-muted">
                        Доступная сумма: {{ available_currency_balance }} {{ ticker }}
                    </span>
                    <b-link @click="outputAllAvailableBalance">
                        Вывести все средства
                    </b-link>
                </div>

                <div class="d-flex justify-content-between" style="font-size: 12px; margin-top: 8px;">
                    <span class="text-muted">
                        Приоритет
                    </span>
                    <b-dropdown
                        variant="muted"
                        size="sm"
                        right
                        no-caret
                        style="padding: 0"
                      >

                        <template #button-content>
                            <div class="d-flex">
                                <span class="text-primary mb-auto mb-auto font-weight-normal" style="font-size: 12px;">{{ priority.label }}</span>
                                <feather-icon icon="ChevronDownIcon" class="text-primary" style="margin: auto 2px; margin-right: 0;" />
                            </div>
                        </template>

                        <b-dropdown-item v-for="(item, index) in priorities" :key="index" :active=" item.value ===  priority.value " @click="priority = item">{{ item.label }}</b-dropdown-item>

                    </b-dropdown>
                </div>

                <hr>

                <div v-if="fee" class="d-flex justify-content-between" style=" margin-bottom: 6px;">
                    <span>Комиссия при транзакции</span>
                    <span>{{ fee_currency }} {{ ticker }}</span>
                </div>

                <div v-if="fee" class="d-flex justify-content-between" style=" margin-bottom: 6px;">
                    <span>Общая сумма</span>
                    <span>{{ total_amount }} {{ ticker }}</span>
                </div>

                <b-alert :show="!isAmountAvailable && amount && isAddressValide && fee && sign" variant="danger" class="font-weight-normal" style="padding: 14px; margin-top: 16px; font-size: 12px;">
                    На балансе кошелька недостаточно средств для осуществления перевода и оплаты комиссии блокчейна. <br> <strong>Рекомендуем уменьшить сумму перевода.</strong>
                </b-alert>
                <b-alert :show="error" variant="danger" class="font-weight-normal" style="padding: 14px; margin-top: 16px; font-size: 12px;">
                    {{ error_msg }}
                </b-alert>

                <b-button @click="output" :disabled="!isCanSend" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">

                    <div v-if="is_loading" class="d-flex justify-content-center">
                        <span class="mt-auto mb-auto ml-1">Отправка...</span>
                    </div>
                    <span v-else>
                        Вывод
                    </span>

                </b-button>

            </div>
        </transition>
    </b-overlay>
    
</template>

<script>
    
    import vSelect from 'vue-select'
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain/"

    var Validator = require('wallet-address-validator');

    import * as SuccessAnimeView from "@/assets/anime/success.json"


    export default {

        data() {
            return {
                
                address: null,
                ticker: "BTC",
                amount: null,
                fee: null,
                sign: null,
                is_loading: false,
                is_disable_send: false,
                
                select: { label: "Основной кошелек", value: 1 },
                priority: { label: "Средний", value: "medium" },
                
                priorities: [
                    { label: "Низкий", value: "low" },
                    { label: "Средний", value: "medium" },
                    { label: "Высокий", value: "height" },
                ],
                
                wallets: [
                    { label: "Основной кошелек", value: 1 },
                    // { label: "Расчетный кошелек", value: 2 }
                ],
                
                tx: null,
                
                animationData: { 
                    animationData: SuccessAnimeView.default,
                    loop: false
                }, 
                
                error_msg: "",
                error: false,
            }
        }, 
        props: {
            wallet: {
                type: Object
            },
        },
        methods: {            
            
            createrawtransaction() {
                if( !this.isAddressValide || !this.amount ) return;                
                this.$request.post( "syswal.createrawtransaction", this.params ).then( result => {
                    this.fee = result.fee;
                    this.sign = result.sign;    
                });
                
            },
            
            modalClose() {
                this.$emit("close", 'output' );
            },
            
            output() {
                const t = this;

                this.error = false;
                this.is_loading = true;
                this.is_disable_send = true;

                this.$request.post("syswal.send", this.transaction ).then( result => {
                    t.$emit("afterTransactionSend", result );
                    t.tx = result;
                    t.is_loading = false;
                }).catch(err => {
                    this.is_loading = false;

                    if (err.code == 1 && err.status == "fail" && err.message == "Insufficient funds") {
                        this.error_msg = "На кошельке недостаточно средств"
                        this.error = true;
                    } else {
                        this.error_msg = err.message;
                        this.error = true;
                        this.is_disable_send = false;
                    }
                });
                
            },
            
            changeSelectedWallet( value ) {
               if( value !== 0 && value !== 1 ) return;
               this.select = this.wallets[value];
            },
            
            focusDropdown( args ) {
                let input = document.querySelector("#output-select input");
                if( input ) {
                    input.focus();
                }                
            },
            
            outputAllAvailableBalance( args ) {
                
                switch( this.ticker ) {
                    case 'BTC' : this.amount = this.available_balance; break;
                    default: this.amount = this.available_balance * Blockchain.tickers.values[User.currency].last; break;
                }
                
            },
            
            start_and_end(str, start = 10, end = 5) {
                if (str.length > 20) {
                    return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
            
            changeCurrencyType( ticker ) {
                
                if( ticker === this.ticker ) return;
                
                if( this.amount ) {
                    switch (ticker) {
                        case "BTC" :  this.amount =  this.amount / Blockchain.tickers.values[User.currency].last; break;
                        default:   this.amount = this.amount * Blockchain.tickers.values[User.currency].last; break;
                    }
                }
                
                this.ticker = ticker;
            }
        },
        computed: {            
            
            transaction() {
                var amount = this.amount;
                
                if( this.ticker !== "BTC" ) {
                    amount = amount / Blockchain.tickers.values[User.currency].last;
                }

                return {
                    address : this.address,
                    amount:  Math.round10(amount, -10),
                    priority: this.priority.value,
                    from: this.select.value,
                    estimatefee: this.fee,
                    sign: this.sign
                }
                
            },
            
            params() {
                var amount = this.amount;
                
                if( this.ticker !== "BTC" ) {
                    amount = amount / Blockchain.tickers.values[User.currency].last;
                }
                
                return {
                    address : this.address,
                    amount: amount,
                    priority: this.priority.value,
                    from: this.select.value
                }
                
            },
            
            available_currency_balance() {
                
                if( this.ticker === "BTC" ) {
                    return (this.available_balance).toFixed(5);
                }
                
                return (this.available_balance * Blockchain.tickers.values[User.currency].last).formatMoney(2," "," ");
                
            },
            
            available_balance() {
               return this.wallet.balance
            },
            
            isAmountAvailable() {
                   
                var amount = Math.round10(this.amount, -10);
                
                if( this.ticker !== "BTC" ) {
                   amount = amount / Blockchain.tickers.values[User.currency].last;
                }
                
                var result = this.available_balance >= ( parseFloat(amount) + parseFloat(this.fee) );
                
                return result;
                
            },
            
            isCanSend() {

                if( this.is_disable_send ) return false;
                if( !this.isAddressValide ) return false;
                if( !this.amount ) return false;
                if( !this.sign ) return false;
                if( !this.isAmountAvailable ) return false;
                
                return true;
                
            },
            
            fee_currency() {
                
                if( this.ticker === "BTC" ) {
                    return Math.round10(this.fee, -10);
                }
                
                return (parseFloat(this.fee) * Blockchain.tickers.values[User.currency].last).formatMoney(2," "," ");
                
            },
            
            total_amount() {
                
                if( this.ticker === "BTC" ) {
                    return Math.round10(parseFloat(this.amount) + parseFloat(this.fee), -10);
                }
                                                
                return (this.amount + (this.fee * Blockchain.tickers.values[User.currency].last)).formatMoney(2," "," ");
                
            },
            
            isAddressValide() {
                if( !this.address ) return false; 
                return Validator.validate( this.address, "BTC" );
            },
            
            currency() {
                return User.currency;
            },
            user() {
                return User.self
            }
        },
        components: {
            vSelect
        },
        watch: {
            
            "priority" : function() {
                if( this.amount && this.isAddressValide ) {
                    this.createrawtransaction();
                }
            },

            "address" : function() {
                if ( this.amount && this.isAddressValide ) {
                    this.createrawtransaction();
                }
            },
            
            "amount" : function( val ) {
                if( val && val > 0 ) {
                    this.createrawtransaction();
                }
            }
        },
        mounted() {

            if( this.$route.query.to && Validator.validate(this.$route.query.to, "BTC") ) {
                this.address = this.$route.query.to;
            }
            
            if( this.$route.query.amount ) {
                this.amount = this.$route.query.amount;
            }
            
//            setTimeout(() => {
//                this.tx = {
//                    address: "bc1quswdt5xfu3dvt5qre2kndsuztf080dggwnqcs4",
//                    amount: 0.0001,
//                    txid: "63ee1d80ebf39fee92a10537ba4deafa87c35ce00c9316196d6e3d611b816389",
//                    fee: -0.0001692
//                };
//            }, 1500 );

        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
 