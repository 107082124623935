<template>

    <b-card no-body>
        <b-card-header>
            <b-card-title>
                Пользователи
            </b-card-title>

            <b-card-title style="display: flex">
                <b-form-input  type="text" placeholder="Поиск" @change="get(true)" v-model="searchUsers" size="sm" style="border-radius: 100px;"  />
            </b-card-title>
        </b-card-header>
        <!-- <b-table-simple v-if='is_loading' animation="fade" :columns='2' :rows='5'>
            <b-thead>
                <b-tr>
                    <b-th class="text-nowrap" style="width: 400px">Пользователь</b-th>
                    <b-th class="text-nowrap">Расчетный баланс
                        <feather-icon
                            v-if="orderBy === 'desc'"
                            icon="ChevronDownIcon"
                            size="16"
                            @click="updateOrder"
                        />
                        <feather-icon
                            v-else
                            icon="ChevronUpIcon"
                            size="16"
                            @click="updateOrder"
                        />
                    </b-th>
                </b-tr>
            </b-thead>
            
        </b-table-simple> -->
        <b-skeleton-table v-if='is_loading' animation="fade" :columns='2' :rows='5'>
            
        </b-skeleton-table>
        <b-table-simple v-else responsive>
            <b-thead>
                <b-tr>
                    <b-th class="text-nowrap" style="width: 400px">Пользователь</b-th>
                    <b-th class="text-nowrap">Расчетный баланс
                        <feather-icon
                            v-if="orderBy === 'desc'"
                            icon="ChevronDownIcon"
                            size="16"
                            @click="updateOrder"
                        />
                        <feather-icon
                            v-else
                            icon="ChevronUpIcon"
                            size="16"
                            @click="updateOrder"
                        />
                    </b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="user in dataSource.items" :key="user.id" v-ripple @click="$router.push({ name: 'admin-users-view', params: { id: user.id }})" class=" font-weight-normal cursor-pointer">
                    <b-th class="font-weight-normal text-nowrap">
                        <div class="d-flex">
                            <b-avatar
                                :variant="Users.utils.resolveAvatarColor( user.id )"
                                :text="Users.utils.capitalize(`${user.firstName}`, 1 )"
                            />
                            <div class="d-flex flex-column" style="margin-left: 12px;">
                                <span class="font-weight-bolder text-primary text-capitalize m-0">
                                    {{ user.firstName }} {{ user.lastName }}
                                </span>
                                <span class="text-muted" style="font-size: 11px;">#{{ user.id }}</span>
                            </div>
                        </div>
                    </b-th>
                    <b-th class="font-weight-normal">{{ parseFloat(user.balance).formatMoney(8," "," ") }} ₿ <span class="text-muted">≈ {{currency_balance(user.balance)}} $</span></b-th>
                </b-tr>
            </b-tbody>
        </b-table-simple>

         <b-card-body v-if="!is_loading" class="d-flex justify-content-between pt-0 pb-0">
                <span class="font-weight-bolder">Всего: {{ dataSource.total_count }}</span>

                <b-pagination-nav 
                    :value="dataSource.current_page" 
                    :link-gen="linkGen" 
                    :number-of-pages="dataSource.last_page" 
                    use-router 
                ></b-pagination-nav>
        </b-card-body>

    </b-card>
    
</template>

<script>
    
    import Users from "@/modules/admin/users/"
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"

    export default {
        data() {
            return {
                Users,
                dataSource: {
                    items: [],
                    current_page: 1,
                    has_more_pages: false,
                    last_page: 1,
                    total_count: 0
                },    
                searchUsers: '',
                is_loading: false,

                orderBy: "desc"

            }
        },
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'users' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            if( perms.read === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            next( true );
            
        },
        methods: {

            updateOrder() {
                this.orderBy = this.orderBy === 'desc' ? 'asc' : 'desc';
                this.get(true);
            },
            
            linkGen( pageNum ) {
                return {
                    name: this.$route.name,
                    query: {
                        page: pageNum
                    }
                }        
            },

            get(clear = false) {

                this.is_loading = true

                if (clear) this.$route.query.page = 1

                this.$request.get("users/balances", {
                    page: this.$route.query.page,
                    search: this.searchUsers,
                    orderby: this.orderBy
                }).then( rsp => {
                    Object.keys( this.dataSource ).forEach( key => {
                        if( rsp.hasOwnProperty(key) ) {
                            this.dataSource[key] = rsp[key];
                        }
                    });
                    this.is_loading = false
                });
            },

            currency_balance(balance) {
                return (Blockchain.tickers.values.USD.last * balance).formatMoney(2," "," ");
            }

        },
        watch: {
            "$route.query" : function () {
                this.get();
            }
        },
        mounted() {
            this.get();
        }
    }

</script>